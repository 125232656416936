<template>
    <user-dashboard-layout>
        <v-container>
            dashboard.index
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../layouts/UserDashboardLayout";
    export default {
        name: "dashboard-index",
        components: {UserDashboardLayout},
        data: function () {
            return {}
        },
    }
</script>

<style scoped>

</style>
